import $ from 'dom7';
import Framework7 from 'framework7/bundle';

// Import F7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

import Base from './base.js';

// Import Routes
import routes from './routes.js';
// Import Store
import store from './store.js';

// Import main app component
import App from '../app.f7.html';


var app = new Framework7({
  name: 'Ha Xinh Admin', // App name
  theme: 'auto', // Automatic theme detection
  el: '#app', // App root element
  component: App, // App main component

  // App store
  store: store,
  // App routes
  routes: routes,
  // Register service worker
  serviceWorker: {
    path: '/service-worker.js',
  },
  on: {
    pageInit: function (e) {
      // $('a.tab-link').removeClass('tab-link-active');
      // $('a[href="'+e.route.path+'"]').addClass('tab-link-active');
      // console.log(e.route.name);
      $('#app-title').text(e.route.name)
    }
  },
  touch: {
    tapHold: true //enable tap hold events
  },
});
Base.init(app);
document.addEventListener("visibilitychange", function() {
  if (document.visibilityState === 'visible') {
    Base.init(app);
  }
});
