/** @jsx $jsx */
import { $jsx } from 'framework7';
import $ from 'dom7';
import Base from '../js/base';
export default (function (props, _ref) {
  var $ = _ref.$,
      $emit = _ref.$emit,
      $f7 = _ref.$f7,
      $f7route = _ref.$f7route;
  var page_name = 'comment-filter-popup';
  var $popup;

  var search = function search() {
    $popup = $f7route.modal.$el;
    var data = $f7.form.convertToData($popup.find('.filter-form'));
    Base.app.emit('commentFilterChange', data);
    $popup.find('.popup-close').trigger('click');
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
<div class="popup" data-name="${page_name}">
  <div class="view">
    <div class="page">
      <div class="navbar">
        <div class="navbar-bg"></div>
        <div class="navbar-inner">
          <div class="title">Lọc góp ý</div>
          <div class="right"><a href="#" class="link popup-close"><i class="icon f7-icons">multiply</i></a></div>
        </div>
      </div>
      <div class="page-content">    
        <div class="list no-margin">
          <ul class="filter-form">
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Loại góp ý</div>
                <div class="item-input-wrap input-dropdown">
                  <select name="category">
                    <option value="">Tất cả</option>
                    ${Base.AppConsts.commentCategories.map(category=>$h`
                      <option value="${category.id}">${category.name}</option>
                    `)}
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Người góp ý</div>
                <div class="item-input-wrap input-dropdown">
                  <select name="id_emp">
                    <option value="">Không chọn</option>
                    ${Base.AppConsts.workingEmployees().map(emp=>$h`
                      <option value="${emp.id}">${emp.name}</option>
                    `)}
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label">Người được góp ý</div>
                <div class="item-input-wrap input-dropdown">
                  <select name="id_emp_victim">
                    <option value="">Không chọn</option>
                    ${Base.AppConsts.workingEmployees().map(emp=>$h`
                      <option value="${emp.id}">${emp.name}</option>
                    `)}
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content">
              <div class="item-inner">
                <button class="button" @click=${search}>Lọc danh sách</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
`
    }
    ;
});