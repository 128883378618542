import LoginPage from '../pages/login.f7.html';
import HomePage from '../pages/home.f7.html';
import IncomePage from '../pages/income.f7.html';
import DayoffPage from '../pages/dayoff.f7.html';
import CrossReviewPage from '../pages/cross-review.f7.html';
import CrossReviewHomePage from '../pages/cross-review-home.f7.html';
import EmployeePage from '../pages/employee.f7.html';
import ChuyenBanTonPage from '../pages/chuyen-ban-ton.f7.html';
import WarningCustomerPage from '../pages/warning-customer.f7.html';
import UpdatePage from '../pages/update.f7.html';
import Nvut from '../pages/nvut.f7.html';
import LamodauBaonhieulanPage from '../pages/lamodau-baonhieulan.f7.html';
import NhapHang from '../pages/nhaphang.f7.html';

//19-10-2023
import KhoThocHome from '../kho-thoc/home.f7.html';
import KhoThocForm from '../kho-thoc/popup-form.f7.html';
import KhoThocTemplate from '../kho-thoc/popup-template.f7.html';
import KhoThocUsed from '../kho-thoc/popup-used.f7.html';
import KhoThocDetail from '../kho-thoc/popup-detail.f7.html';

//02-09-2022
import BaocaoEOD from '../pages/baocao-eod.f7.html';
import BaocaoEODPopup from '../pages/baocao-eod-chitiet.f7.html';

//03-01-2022
import CommentPage from '../pages/comment.f7.html';

//21-04-2011
import ReportHuyhieuPage from '../pages/report-huyhieu.f7.html';

import IncomeBonusPopup from '../pages/income-bonus-popup.f7.html';
import IncomeTamUngPopup from '../pages/income-tamung-popup.f7.html';
import EmployeeListPopup from '../pages/employee-list-popup.f7.html';
import CrossReviewDetailPopup from '../pages/cross-review-detail-popup.f7.html';
import CommentFilterPopup from '../pages/comment-filter-popup.f7.html';

import NvutTieuchiPopup from '../pages/nvut-tieuchi-popup.f7.html';
import NvutBaocaoPopup from '../pages/nvut-baocao-popup.f7.html';
import NvutLoinhacPopup from '../pages/nvut-loinhac-popup.f7.html';

import NotFoundPage from '../pages/404.f7.html';

var routes = [
  {
    path: '/', name:'Login',
    component: LoginPage,
    options: {
      transition: 'f7-fade',
    }
  },
  { path: '/kho-thoc-home/', component: KhoThocHome, options: { transition: 'f7-fade', }},
  { path: '/kho-thoc-form/', popup:{component: KhoThocForm}},
  { path: '/kho-thoc-template/:evt', popup:{component: KhoThocTemplate}},
  { path: '/kho-thoc-used/', popup:{component: KhoThocUsed,}},
  { path: '/kho-thoc-detail/:id_emp/:month', popup:{component: KhoThocDetail,}},
  {
    path: '/nhaphang/', name:'Đơn nhập',
    component: NhapHang,
    options: {
      transition: 'f7-fade',
    }
  },
  {path: '/baocao-eod-chitiet/', popup:{ component:BaocaoEODPopup}},
  {
    path: '/baocao-eod/', name:'Chốt tiền',
    component: BaocaoEOD,
    options: {
      transition: 'f7-fade',
    }
  },
  {
    path: '/nvut/', name:'Nhân viên ưu tú',
    component: Nvut,
    options: {
      transition: 'f7-fade',
    }
  },
  {
    path: '/report-huyhieu/', name:'Báo cáo khen thưởng',
    component: ReportHuyhieuPage,
    options: {
      transition: 'f7-fade',
    }
  },
  {
    path: '/lamodau-baonhieulan/', name:'Làm ở đâu ? lần',
    component: LamodauBaonhieulanPage,
    options: {
      transition: 'f7-fade',
    }
  },
  {
    path: '/update/', name:'Cập nhật',
    component: UpdatePage,
    options: {
      transition: 'f7-fade',
    }
  },
  {
    path: '/comment/', name:'Góp ý',
    component: CommentPage,
    options: {
      transition: 'f7-fade',
    }
  },
  {
    path: '/warning-customer/', name:'Nghi vấn tích điểm',
    component: WarningCustomerPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/chuyen-ban-ton/', name:'Báo cáo chuyển/bán/tồn',
    component: ChuyenBanTonPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/home/', name:'Lịch làm việc',
    component: HomePage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/dayoff/', name:'Lịch nghỉ',
    component: DayoffPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/income/', name:'Thu nhập',
    component: IncomePage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/cross-review/', name:'Kết quả đánh giá',
    component: CrossReviewPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/cross-review-home/', name:'Đánh giá chéo',
    component: CrossReviewHomePage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/employee/', name:'Nhân viên',
    component: EmployeePage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/employee-list-popup/:eventName',
    popup:{
      component:EmployeeListPopup,
    },
  },
  {
    path: '/income-bonus-popup/',
    popup:{
      component:IncomeBonusPopup,
    },
  },
  {
    path: '/income-tamung-popup/',
    popup:{
      component:IncomeTamUngPopup,
    },
  },
  {
    path: '/cross-review-detail/:actor/:actor_name/:victim/:victim_name/:month',
    popup:{
      component: CrossReviewDetailPopup,
    }
  },
  {
    path: '/comment-filter-popup/',
    popup:{
      component:CommentFilterPopup,
    },
  },
  {path: '/nvut-tieuchi-popup/', popup:{ component:NvutTieuchiPopup}},
  {path: '/nvut-baocao-popup/', popup:{ component:NvutBaocaoPopup}},
  {path: '/nvut-loinhac-popup/', popup:{ component:NvutLoinhacPopup}},
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
