/** @jsx $jsx */
import { $jsx } from 'framework7';
import $ from 'dom7';
import Base from './js/base.js';
export default (function (props, _ref) {
  var $ = _ref.$,
      $f7 = _ref.$f7,
      $tick = _ref.$tick;

  var profile = function profile() {
    Base.profile();
  };

  $tick(function () {
    var i = setInterval(function () {
      if (Base.user) {
        if (Base.user.role == 2) {
          $('.left-navigation li').each(function () {
            var role = $(this).attr("role");
            if (role !== "2") $(this).remove();
          });
          clearInterval(i);
        }
      }
    }, 100);
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app" class="theme-dark color-theme-orange">

    <div class="theme-dark panel panel-left panel-cover panel-init" style="background-color: #121212">
      <div class="list no-hairlines left-navigation">
        <ul>
          <li>
            <a href="/home/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">calendar</i></div>
                <div class="item-inner">Lịch làm việc</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/dayoff/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">equal_square</i></div>
                <div class="item-inner">Lịch nghỉ</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/baocao-eod/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">sum</i></div>
                <div class="item-inner">Chốt tiền</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/cross-review/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">checkmark_2</i></div>
                <div class="item-inner">Kết quả đánh giá</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/cross-review-home/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">arrow_right_arrow_left</i></div>
                <div class="item-inner">Xem đánh giá chéo</div>
              </div>
            </a>
          </li>
          <!-- <li>
            <a href="/comment/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">quote_bubble</i></div>
                <div class="item-inner">Góp ý với lãnh đạo</div>
              </div>
            </a>
          </li> -->
          <!-- <li>
            <a href="/income/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">money_dollar_circle</i></div>
                <div class="item-inner">Chốt lương</div>
              </div>
            </a>
          </li> -->
          <li>
            <a href="/employee/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">person_2_alt</i></div>
                <div class="item-inner">Nhân viên</div>
              </div>
            </a>
          </li>
          <li role="2">
            <a href="/kho-thoc-home/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">circle_grid_hex_fill</i></div>
                <div class="item-inner">Kho thóc</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/lamodau-baonhieulan/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">rectangle_grid_3x2_fill</i></div>
                <div class="item-inner">Làm ở đâu ? lần</div>
              </div>
            </a>
          </li>
          <!-- <li>
            <a href="/report-huyhieu/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">checkmark_seal</i></div>
                <div class="item-inner">Khen thưởng</div>
              </div>
            </a>
          </li> -->
          <li role="2">
            <a href="/update/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">gear_alt_fill</i></div>
                <div class="item-inner">Cập nhật</div>
              </div>
            </a>
          </li>
          <!-- <li>
            <a href="/chuyen-ban-ton/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">rectangle_3_offgrid</i></div>
                <div class="item-inner">Báo cáo chuyển/bán</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/warning-customer/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">question_circle</i></div>
                <div class="item-inner">Nghi vấn tích điểm</div>
              </div>
            </a>
          </li> -->
        </ul>
      </div>

    </div>

    <!-- Your main view, should have "view-main" class -->
    <div class="view view-main view-init safe-areas" data-url="/">

    </div>


    <!-- Popup -->
    <div id="profile-popup">
      <div class="popup profile-popup">
        <div class="view">
          <div class="page">
            <div class="navbar">
              <div class="navbar-bg"></div>
              <div class="navbar-inner">
                <div class="title">Thông tin tài khoản</div>
                <div class="right">
                  <a href="#" class="link popup-close"><i class="icon f7-icons">multiply</i></a>
                </div>
              </div>
            </div>
            <div class="toolbar tabbar toolbar-bottom">
              <div class="toolbar-inner">
                <a href="#" class="button button-fill color-red logout-button">Thoát</a>
              </div>
            </div>
            <div class="page-content">
              <div class="list no-hairlines-md no-margin">
                <ul>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Mật khẩu hiện thời</div>
                        <div class="item-input-wrap">
                          <input type="password" name="current-password"/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Mật khẩu mới</div>
                        <div class="item-input-wrap">
                          <input type="password" name="new-password"/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Xác nhận mật khẩu</div>
                        <div class="item-input-wrap">
                          <input type="password" name="confirm-password"/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner text-align-center">
                        <a href="#" class="change-password-button">Thay đổi mật khẩu</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
`
    }
    ;
});