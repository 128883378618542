import $ from 'dom7';
import Base from '../js/base.js';
import Utils from '../js/utils.js';
import AutoNumeric from 'autonumeric';

const Employee = {
  rows:null,
  currentRow:null, //Nhân viên được chọn hiện thời
  incomeRow:null, //Dữ liệu thu nhập của nhân viên được chọn trong trang Chốt lương
  init:function(){},
  list:async function(){
    let loader = Base.app.dialog.preloader('Đang tải danh sách...');
    let rs = await Base.api('Employee','list',{});
    loader.close();
    this.rows = rs.rows;
    return rs;
  },
  unauthorizedAbsence:function(item){
    Base.app.dialog.confirm(`Xác nhận ${item.name} NGHỈ KHÔNG PHÉP? (-2 ngày công)`,'Xác nhận',async function(){
      let loader = Base.app.dialog.preloader();
      let rs = await Base.api('Income','unauthorizedAbsence', item);
      loader.close();
      if(rs.result)
        Utils.notiInfo('Lưu lại thành công');
    });
  },
  exceededAbsence:function(item){
    Base.app.dialog.confirm(`Xác nhận ${item.name} NGHỈ QUÁ NGƯỜI? (-1 ngày công)`,'Xác nhận',async function(){
      let loader = Base.app.dialog.preloader();
      let rs = await Base.api('Income','exceededAbsence', item);
      loader.close();
      if(rs.result)
        Utils.notiInfo('Lưu lại thành công');
    });
  },
  ThuongPhat:{
    //item = {name, id_emp, thang, nam}
    //saveSuccess = Employee.incomeRow.thuongphat_rows.push(rs.inserted_row);
    openForm:function(item, savedSuccess = null){
      let self = this;
      let d = new Date();
      item.nam = item.nam || d.getFullYear();
      item.thang = item.thang || d.getMonth()+1;
      let dialog = Base.app.dialog.create({
        title:'Thưởng/Phạt',
        content:`
          <div class="list no-hairlines no-margin no-padding">
            <ul class="text-align-left form-thuong-phat">
              <li class="item-content item-input">
                <div class="item-inner text-align-left">
                  <div class="item-title item-label">Tên cán bộ: ${item.name}<input type="hidden" name="id_emp" value="${item.id_emp}"/></div>
                </div>
              </li>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <label class="radio"><input type="radio" name="loai" value="1" /><i class="icon-radio"></i></label> Thưởng
                    <label class="radio"><input type="radio" name="loai" value="-1" /><i class="icon-radio"></i></label> Phạt
                  </div>
                </div>
              </li>
              <li class="item-content item-input">
                <div class="item-inner">
                <div class="item-title item-label">Số tiền</div>
                  <div class="item-input-wrap"><input type="text" name="so_tien" /></div>
                </div>
              </li>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">Lý do</div>
                  <div class="item-input-wrap"><input type="text" name="ly_do" /></div>
                </div>
              </li>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">Tháng</div>
                  <div class="item-input-wrap"><input type="text" name="thang" value="${item.thang}" /></div>
                </div>
                <div class="item-inner">
                  <div class="item-title item-label">Năm</div>
                  <div class="item-input-wrap"><input type="text" name="nam" value="${item.nam}" /></div>
                </div>
              </li>
            </ul>
          </div>
        `,
        on:{
          open:function(dialog){
            new AutoNumeric('input[name="so_tien"]', Base.autoNumericConfig);
          }
        },
        buttons:[
          {text:'Huỷ'},
          {text:'OK', color:'orange', close:false, onClick:async function(dialog){
            let rs = await self.save(dialog);
            if(rs.result) {
              dialog.close();
              Utils.notiInfo('Lưu lại thành công');
              if(savedSuccess)
                savedSuccess(rs);
            }
          }}
        ]
      }).open();
    },
    save: async function(dialog){
      let data = Base.app.form.convertToData($(dialog.el).find('ul.form-thuong-phat'));
      data.so_tien = Utils.str2Num(data.so_tien);
      if(data.loai == null) {
        Utils.notiError('Chưa chọn loại');
        return {result:false};
      }
      if(data.so_tien == 0) {
        Utils.notiError('Chưa điền số tiền');
        return {result:false};
      }
      if(data.ly_do == '') {
        Utils.notiError('Phải có lý do Thưởng/Phạt');
        return {result:false};
      }
      let loader = Base.app.dialog.preloader();
      let rs = await Base.api('Income','saveThuongPhat', data);
      loader.close();
      return rs;
    }
  },
  TamUng:{
    //item = {name, id_emp, ngay, thang, nam}
    //saveSuccess = Employee.incomeRow.tamung_rows.push(rs.inserted_row);
    openForm:function(item, savedSuccess = null){
      let self = this;
      let d = new Date();
      item.ngay = item.ngay || d.getDate();
      item.nam = item.nam || d.getFullYear();
      item.thang = item.thang || d.getMonth()+1;
      let dialog = Base.app.dialog.create({
        title:'Tạm Ứng',
        content:`
          <div class="list no-hairlines no-margin no-padding">
            <ul class="text-align-left form-tam-ung">
              <input type="hidden" name="id" value="${item.id||''}"/>
              <li class="item-content item-input">
                <div class="item-inner text-align-left">
                  <div class="item-title item-label">Tên cán bộ: ${item.name}<input type="hidden" name="id_emp" value="${item.id_emp}"/></div>
                </div>
              </li>
              <li class="item-content item-input">
                <div class="item-inner">
                <div class="item-title item-label">Số tiền</div>
                  <div class="item-input-wrap"><input type="text" name="so_tien" value="${item.so_tien_format||''}" /></div>
                </div>
              </li>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">Nội dung (tuỳ chọn)</div>
                  <div class="item-input-wrap"><input type="text" name="noi_dung" /></div>
                </div>
              </li>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">Ngày</div>
                  <div class="item-input-wrap"><input type="text" name="ngay" value="${item.ngay || ''}" /></div>
                </div>
                <div class="item-inner">
                  <div class="item-title item-label">Tháng</div>
                  <div class="item-input-wrap"><input type="text" name="thang" value="${item.thang || ''}" /></div>
                </div>
                <div class="item-inner">
                  <div class="item-title item-label">Năm</div>
                  <div class="item-input-wrap"><input type="text" name="nam" value="${item.nam || ''}" /></div>
                </div>
              </li>
            </ul>
          </div>
        `,
        on:{
          open:function(dialog){
            new AutoNumeric('input[name="so_tien"]', Base.autoNumericConfig);
          }
        },
        buttons:[
          {text:'Huỷ'},
          {text:'OK', color:'orange', close:false, onClick:async function(dialog){
            let rs = await self.save(dialog);
            if(rs.result) {
              dialog.close();
              Utils.notiInfo('Lưu lại thành công');
              if(savedSuccess)
                savedSuccess(rs);
            }
          }}
        ]
      }).open();
    },
    save: async function(dialog){
      let data = Base.app.form.convertToData($(dialog.el).find('ul.form-tam-ung'));
      data.so_tien = Utils.str2Num(data.so_tien);
      if(data.so_tien == 0) {
        Utils.notiError('Chưa điền số tiền');
        return {result:false};
      }
      let loader = Base.app.dialog.preloader();
      let rs = await Base.api('Income','saveTamUng', data);
      loader.close();
      return rs;
    }
  },
}

export default Employee;
