import Framework7 from 'framework7/bundle';
import $ from 'dom7';
import Utils from './utils.js';
var Base = {
  app:null,
  token:null,
  tokenKey:'admapp-token',
  user:null,
  userKey:'admapp-user',
  apiUrl:'',
  language:'vi-VN',
  apiEndPoint: (process.env.NODE_ENV === 'development') ? 'https://haxinh.com/api-dev/?' : 'https://haxinh.com/api-v1/?',
  // apiEndPoint:'https://haxinh.com/api-v1/?',
  uploadURL:'https://haxinh.com/uploads',
  dateTimeFormat:'D/M/Y HH:mm',
  dateFormat:'D/M/Y',
  autoNumericConfig:{
    digitGroupSeparator        : '.',
    decimalCharacter           : ',',
    decimalCharacterAlternative: '.',
    decimalPlaces:0,
    selectOnFocus:false,
    caretPositionOnFocus:null,
    watchExternalChanges:true,
  },
  draftKey:null,
  branches:[],
  branchesLoaded:false,
  initdata:{},
  init: async function(app){
    let self = this;
    self.app = app;

    // GET BRANCHES
    var rs = await Base.api('App','initdata',{});
    self.branches = rs.branches;
    self.branchesLoaded = true;
    self.initdata = rs;

    this.initdataLoaded = true;

    // Hiển thị thông báo khách có nghi vấn
    // Chỉ hiển thị nếu đã login
    // let checkedInterval = setInterval(()=>{
    //   if(this.token !== null && this.user !== null) {
    //     clearInterval(checkedInterval);
    //     let customers = Base.initdata.warning_customers.filter(c=>c.viewed_at === null);
        
    //     if(customers.length > 0) {
    //       Base.app.dialog.confirm(
    //         `Phát hiện ${customers.length} khách có >=4 đơn tích điểm/tuần hoặc đã đổi điểm >=200.000 (12 điểm). Ấn OK để xem`,
    //         ()=>Base.app.views.main.router.navigate('/warning-customer/')
    //       );
    //     }

    //     if(Base.initdata.unread_comments_count > 0) {
    //       Base.app.notification.create({
    //         title: 'Hà Xinh',
    //         subtitle: `Có ${Base.initdata.unread_comments_count} góp ý mới`,
    //         closeButton: true,
    //       }).open();
    //     }
        
    //   }
    // },1000);
  },
  call:async (params)=>{
    return await Base.api('Base','call', params);
  },
  api:async function(mdl,action,postdata){
    let data = postdata;
    data.token = localStorage.getItem(Base.tokenKey);
    try {
      let rs = await Framework7.request({
        url:Base.apiEndPoint+`mdl=${mdl}&action=${action}`,
        method:'post',
        dataType:'json',
        data:data
      });
      return rs.data;
    } catch(err) {
      console.log(err);
      return {};
    }
  },
  logout:function(){
    let self = this;
    localStorage.removeItem(self.tokenKey);
    localStorage.removeItem(self.userKey);
    self.token = null;
    self.user = null;
    self.app.views.main.router.navigate('/');
  },
  profilePopup:function(content){
    let self = this;
    self.app.popup.create({
      content:content,
      on:{
        open:function(popup){
          let $el = $(popup.el);
          $el.find('.logout-button').click(function(){
            popup.close();
            self.logout();
          })
          $el.find('.change-password-button').click(async function(){
            let a = $el.find('input[name=current-password]').val().trim();
            let b = $el.find('input[name=new-password]').val().trim();
            let c = $el.find('input[name=confirm-password]').val().trim();

            if(a==''||b==''||c=='') {
              Utils.notiError('Không được để mật khẩu trắng');
              return;
            }

            if(b.length < 8) {
              Utils.notiError('Mật khẩu cần ít nhất 8 ký tự');
              return;
            }

            if(b != c) {
              Utils.notiError('Mật khẩu xác nhận không khớp');
              return;
            }
            let current_password = a;
            let new_password = b;
            let rs = await Base.api('Account','updatePassword',{current_password, new_password});
            if(rs.result == 'error') {
              Utils.notiError('Mật khẩu hiện thời không chính xác');
              return;
            }

            if(rs.result == 'success') {
              Utils.notiInfo('Thay đổi mật khẩu thành công');
              popup.close();
            }
          })
        }
      }
    }).open();
  },
  profile:async function(){
    let self = this;
    let content = '';
    let token = localStorage.getItem(self.tokenKey);
    if(token == null || token == undefined) {
      self.loginPopup($('#login-popup').html());
    } else {
      Base.app.dialog.preloader('Kiểm tra thông tin...');
      var rs = await Base.api('App','checkToken',{});
      if(rs.result == 'success') {
        Base.app.dialog.close();
        Base.token = localStorage.getItem(Base.tokenKey);
        Base.user = JSON.parse(localStorage.getItem(Base.userKey));
        self.profilePopup($('#profile-popup').html());
      } else {
        Base.app.dialog.close();
        Base.app.dialog.alert('Đăng nhập tự động thất bại. Vui lòng đăng nhập lại.');
        self.logout();
        self.loginPopup($('#login-popup').html());
      }
    }
  },
  getXuatNhapTonData:async ()=>{
    const rs = await Base.api('XuatNhapTon','getData',{});
    if(rs.result === 'success') {
      Base.baocao_chuyenban = rs.rows;
      return rs.rows;
    } else {
      $f7.dialog.alert('Đã xảy ra lỗi! :(');
    }
    return null;
  },
  getEmployee:(id)=>{
    let emp = Base.initdata.employees.find(e=>e.id === id);
    return (emp) ? emp : {name:'-'};
  },
  /**
   * APP CONSTS
   */
  AppConsts:{
    commentCategories:[
      {id:'colleague',name:'Đồng nghiệp',icon:'<i class="f7-icons icon" style="font-size: 16px">person_2_fill</i>',color:'rgb(228, 209, 46)'},
      {id:'idea',name:'Ý tưởng',icon:'<i class="f7-icons icon" style="font-size: 16px">lightbulb</i>',color:'rgb(98, 228, 46)'},
      {id:'other',name:'Vấn đề',icon:'<i class="f7-icons icon" style="font-size: 16px">question_circle_fill</i>',color:'rgb(228, 58, 46)'},
    ],
    allEmployees:()=>{
      return Base.initdata.employees;
    },
    workingEmployees:()=>{
      return Base.initdata.employees.filter(e=>e.deleted === null);
    }
  },
};

export default Base;
